import React, { useState } from "react";
import "./Contact.scss";

import ContactYouAre from "../../components/ContactYouAre/ContactYouAre";
import ContactRadio from "../../components/ContactRadio/ContactRadio";
import ContactForm from "../../components/ContactForm/ContactForm";
import Banner from "../../components/Banner/Banner";
import Title from "../../components/Title/Title";

import headerDesktopImage from "../../assets/images/desktop/velo-background.png";
import headerMobileImage from "../../assets/images/mobile/velo-background.png";

const Questions = {
  type1: [
    "Engager votre entreprise dans une démarche d’inclusion",
    "En savoir plus sur nos solutions",
    "Sensibiliser vos collaborateurs",
    "Former vos équipes",
    "Accompagner une situation",
    "Devenir partenaire du service solidaire Allo Alex"
  ],
  type2: [
    "Avoir des réponses à vos questions sur la maladie au travail",
    "Témoigner de votre expérience",
    "Rejoindre notre joyeuse équipe engagée"
  ]
};

const Contact = () => {
  const [userType, setUserType] = useState("");
  const [userAsk, setUserAsk] = useState("");

  return (
    <>
      <Banner desktopImage={headerDesktopImage} mobileImage={headerMobileImage}>
        <p>Quels que soient votre demande et votre besoin, parlons-en !</p>
      </Banner>
      <main className="main-contact">
        <section className="section-you-are">
          <Title color="primary">Vous êtes ?</Title>
          <ContactYouAre setUserType={setUserType} />
        </section>
        <section className="section-you-want">
          <Title color="primary">Vous souhaitez ?</Title>
          <div className="contact-radio-container">
            {userType === "type2"
              ? Questions.type2.map((question, idx) => {
                  return (
                    <ContactRadio
                      setUserAsk={setUserAsk}
                      data={question}
                      key={idx}
                    />
                  );
                })
              : Questions.type1.map((question, idx) => {
                  return (
                    <ContactRadio
                      setUserAsk={setUserAsk}
                      data={question}
                      key={idx}
                    />
                  );
                })}
          </div>
        </section>
        <section className="section-coordinate">
          <Title color="primary">Vos coordonnées</Title>
          <ContactForm wish={userAsk} />
          <p className="other-queries">Pour toute autre demande, vous pouvez nous écrire à : <span className="email">bonjour@wecareatwork.com</span></p>
        </section>
      </main>
      <hr
        style={{ borderTop: "5px solid #F75E52" }}
        className="transition-dash"
      />
    </>
  );
};

export default Contact;
