import React from "react";
import Nicolas from "../../assets/images/nicolas-bouzou.png";

import "./EnjeuxInterview.scss";

const EnjeuxInterview = () => {
  return (
    <>
      <article className="enjeux-interview">
        <aside className="enjeux-interview-aside">
          <img src={Nicolas} alt="Nicolas Bouzou" />
        </aside>
        <section className="enjeux-interview-section">
          <p className="enjeux-interview-title">Interview de Nicolas Bouzou</p>
          <p className="enjeux-interview-job">Economiste</p>
          <QuestionAnswer
            question="Pourquoi est-il si important de concilier cancer, maladie et travail ?"
            answer="Le cancer est une maladie qui a une prévalence importante. Dans toutes les entreprises il y a des salariés qui souffrent de cette pathologie ou plus largement d’autres maladies. Du fait de la chronicité et de l’évolution des traitements, il y a de plus en plus de personnes qui sont en état de travailler. Cela nécessite donc de s’adapter et de fournir à ces salariés la possibilité de se maintenir dans l’emploi s’ils le souhaitent. C’est un élément de motivation extraordinaire pour la personne et son entourage. C’est mieux économiquement pour l’entreprise car elle n’a pas à recruter des CDD ou des intérimaires. C’est mieux aussi pour la société en général, pour la Sécurité Sociale notamment, parce que quand la personne travaille, elle continue de cotiser et contribue à son développement. Nous sommes donc sur l’un des rares sujets de politique publique, de politique économique,où toutes les parties engagées y gagnent."
          />
        </section>
      </article>
      <section className="full-interview-answer">
        <QuestionAnswer
          question="Quel est le rôle de l’entreprise ?"
          answer="Nous avons beaucoup de témoignages de salariés pour qui c’est important de travailler. Ça leur permet de conserver un équilibre et il est prouvé que ça a des effets bénéfiques dans le cadre de leur thérapie. L’entreprise a donc un rôle social majeur à jouer. C’est un lieu de vie très important pour les gens, donc ça fait partie du rôle de l’entreprise de faire en sorte que les gens touchés par la maladie soient bien, qu’on puisse aménager leurs conditions de travail s’ils en ont besoin."
        />
        <QuestionAnswer
          question="Concilier maladie et travail pour lutter contre la comédie (in)humaine* ?"
          answer="C’est au coeur du sujet que l’on développe avec Julia de Funes ! Parce qu’il y a vraiment des problèmes de motivation dans les entreprises, des problèmes de désengagement. Mais les entreprises, par rapport à ça, apportent souvent de mauvaises réponses. Elles vont recruter un Chief Happiness Officier, mettre des baby-foot dans les espaces communs… C’est très bien, si c’est en plus. Mais il ne faut pas que ça se substitue au management ! Les gens ont besoin de trois choses : de sens, d’autonomie et d’autorité (dans le bon sens du terme). Quand vous avez dans un service une personne qui est confrontée à la maladie, il faut lui donner davantage d’autonomie pour qu’elle puisse adapter son travail. Pour l’équipe et pour les RH, ça a beaucoup de sens. Face à un enjeu sociétal majeur, si l’entreprise travaille sur le sujet des maladies chroniques dans l’entreprise ça a beaucoup de sens. Donc oui, nous sommes vraiment au coeur des enjeux dont on parle Julia et moi dans *La comédie (in)humaine, aux éditions de l’Observatoire."
        />
        <QuestionAnswer
          question="Quels impacts sur l’absentéisme ?"
          answer="Pour lutter contre l’absentéisme, le coût de l’absentéisme plus exactement, il y a deux façons de faire. Il y a une vision comptable : on va moins bien indemniser. Puis il y a la vision économique, et c’est celle que j’essaye de défendre. Il s’agit de prendre en compte la spécificité des gens qui souffrent d’une maladie chronique et de faire en sorte d’aménager leur travail, leur temps de travail, leur conditions de travail pour qu’ils puissent venir travailler s’ils le souhaitent et ainsi continuer de contribuer à la création de revenu pour l’entreprise.Si vous êtes dans une entreprise où quand vous avez un pépin de santé, celle-ci sait concilier la vie de ses salariés avec la performance, c’est excellent pour tous !”"
        />
      </section>
    </>
  );
};

const QuestionAnswer = ({ question, answer }) => {
  return (
    <>
      <p className="interview-question">{question}</p>
      <p className="interview-answer">{answer}</p>
    </>
  );
};

export default EnjeuxInterview;
