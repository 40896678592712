import React from "react";
import Banner from "../../components/Banner/Banner";
import LinkButton from "../../components/LinkButton/LinkButton";
import NosSolutionsCard from "../../components/NosSolutionsCard/NosSolutionsCard";

import "./NosSolutions.scss";

import OutlineLogo from "../../assets/icons/Outline.svg";
import SensibilityLogo from "../../assets/icons/sensibility.svg";
import BookLogo from "../../assets/icons/book.svg";
import CallLogo from "../../assets/icons/call.svg";
import CoachLogo from "../../assets/icons/coach.svg";

import headerDesktopImage from "../../assets/images/desktop/header-nossolutions.png";
import headerMobileImage from "../../assets/images/mobile/header-nossolutions.png";

import footerDesktopImage from "../../assets/images/desktop/group.png";
import footerMobileImage from "../../assets/images/mobile/group.png";

const NosSolutions = () => {
  return (
    <>
      <Banner desktopImage={headerDesktopImage} mobileImage={headerMobileImage}>
        <p>
          Le premier accompagnement en ligne pour concilier cancers, maladies et
          travail
        </p>
      </Banner>
      <main className="nos-solutions">
        <section className="nos-solutions-section">
          <p className="nos-solutions-text">
            Pour passer d’une gestion en mode pompier à une vraie politique
            d’inclusion de la maladie au travail. Pour pouvoir agir quelle que
            soit la taille de votre entreprise et son emplacement géographique.
            Pour que plus aucun salarié ne se sente démuni face à une situation
            de maladie au travail. Nous avons créé Alex, votre assistant
            personnel. Il s’appuie sur des outils et services digitaux inédits
            pour vous accompagner.
          </p>
        </section>
        <section className="nos-solutions-section -items">
          <NosSolutionsCard
            logo={CallLogo}
            title="Informations en ligne"
            text="Pour répondre à toutes vos questions, Alex est là pour vous guider sur son blog ou par téléphone au 0 800 400 310."
          >
            <LinkButton
              text="Allo Alex"
              externalLink="https://www.alloalex.com/blog/"
              color="secondary"
              openInNewTab
            />
          </NosSolutionsCard>
          <NosSolutionsCard
            logo={SensibilityLogo}
            title="Sensibilisation en ligne"
            text="Faciliter la mobilisation de vos salariés, quelque soit leur nombre et leur emplacement géographique, telle est notre volonté."
          >
            {/* <LinkButton
              text="Parlons-en"
              linkPath="/contact"
              color="secondary"
            /> */}
          </NosSolutionsCard>
          <NosSolutionsCard
            logo={OutlineLogo}
            title="E-learning"
            text="Appréhender le sujet des maladies au travail, trouver la bonne posture et obtenir des clés concrètes pour mieux accompagner."
          >
            {/* <LinkButton
              text="Connectez-vous"
              linkPath="/"
              color="secondary"
              disabled
            /> */}
          </NosSolutionsCard>
          <NosSolutionsCard
            logo={CoachLogo}
            title="Accompagnement en ligne"
            text="Accompagner chaque salarié grâce à un programme digital innovant, des séances de coaching ou de soutien psychologique individuelles ou collectives."
          >
            {/* <LinkButton
              text="Parlons-en"
              linkPath="/contact"
              color="secondary"
            /> */}
          </NosSolutionsCard>
          <NosSolutionsCard
            logo={BookLogo}
            title="Guide pratique"
            text="Pour savoir comment trouver sa place et comprendre les interrogations de chacun. Vous pouvez vous procurer en ligne notre guide “Cancer et travail”."
          >
            <LinkButton
              text="Commande en ligne"
              externalLink="https://www.eyrolles.com/Entreprise/Livre/cancer-et-travail-9782212565614/"
              color="secondary"
              openInNewTab
            />
          </NosSolutionsCard>
        </section>
      </main>
      <Banner desktopImage={footerDesktopImage} mobileImage={footerMobileImage}>
        <p>Envie d’agir pour mieux concilier maladie et travail ?</p>
        <LinkButton text="Parlons-en" linkPath="/contact" />
      </Banner>
    </>
  );
};

export default NosSolutions;
