import React from "react";
import Header from "../components/Header/Header";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import ElearningCard from "../components/ElearningCard/ElearningCard";
import Pictogrammes from "../components/Pictogrammes/Pictogrammes";
import Soutien from "../components/Soutien/Soutien";
import LinkButton from "../components/LinkButton/LinkButton";
import "./Home.scss";
import SimpleSlider from "../components/SimpleSlider/SimpleSlider";
import Separator from "../components/Separator/Separator";

import Article from "../components/Article/Article";
import Title from "../components/Title/Title";
import Dash from "../components/Dash/Dash";

const Home = () => {
  return (
    <>
      <Header />
      <main className="home">
        <section className="home-section">
          <Article margin>
            <Title color="primary" size="big">
              <Dash color="primary" />
              Concilier cancers, maladies et travail
            </Title>
            <p>
              Nous avons tous à y gagner, humainement et économiquement. Alors
              si vous souhaitez :
              <br />
              - Passer d'une gestion en mode pompier à une véritable politique
              d'inclusion performante et durable
              <br />
              - Construire un fil rouge entre vos politiques handicap, de santé
              au travail, de QVT, de RSE
              <br />
              - Sensibiliser vos salariés <br />
              - Former vos RH et managers <br />- Accompagner les personnes et
              les équipes confrontées à la maladie
            </p>
            <p>
              Notre équipe est là, partout en France, pour vous accompagner.
              Grâce à une méthodologie unique et éprouvée, nous vous proposons
              de co-construire avec vous les meilleures réponses à vos besoins.
            </p>
            <LinkButton
              text="Qui sommes-nous ?"
              linkPath="/qui-sommes-nous"
              color="secondary"
            />
          </Article>
          <VideoPlayer name="presentation-video" source="https://www.youtube.com/embed/SWVN3Rw5BdU"/>
        </section>
        <section className="home-section -center">
          <Pictogrammes />
          <LinkButton
            text="Notre offre"
            linkPath="/notre-offre"
            color="secondary"
          />
        </section>
        <Separator color="-red" />
        <section className="home-section -elearning">
          <ElearningCard />
          <Article position="right" margin>
            <Title color="red" size="big">
              Nos solutions en ligne
              <Dash color="red" position="right" />
            </Title>
            <p>
              Nous voulons rendre notre accompagnement accessible à tous grâce à
              un ensemble de produits et services digitaux. Vous nous avez
              confié votre besoin prioritaire : celui de sensibiliser et former
              vos salariés rapidement, facilement, qu'ils soient 10, 100, 1 000
              ou 10 000. Pour vous, nous développons donc un ensemble de
              solutions en ligne pour vous accompagner à concilier cancers,
              maladies et travail.
            </p>
            <LinkButton
              text="En savoir plus"
              linkPath="/nos-solutions-en-ligne"
              color="secondary"
            />
          </Article>
        </section>
        <section className="home-section">
          <Article margin>
            <Title color="light-blue">
              <Dash color="light-blue" />
              Les résultats chez nos clients ?
            </Title>
            <p>
              Une parole libérée, un dialogue ouvert, un travail bien fait, des
              objectifs atteints, une confiance réaffirmée, un engagement
              renforcé, une image de marque employeur attractive, un taux
              d'absentéisme en baisse, des innovations métiers et une
              performance globale améliorée et durable. <br />
              Mais ils en parlent mieux que nous !
            </p>
          </Article>
          <SimpleSlider />
        </section>
      </main>
      <Soutien />
    </>
  );
};

export default Home;
