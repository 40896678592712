import React from "react";
import wcawLogo from "../../assets/icons/wcaw-logo.png";
import linkedinLogo from "../../assets/icons/linkedin-logo.svg";
import Sidenav from "../Sidenav/Sidenav";
import { Link } from "react-router-dom";
import "./Navbar.scss";

function Navbar(props) {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          <img src={wcawLogo} id="wcaw-logo" alt="we-care-at-work-logo" />
        </Link>
      </div>
      <div className="navbar-buttons">
        <Link style={{ display: "none" }} id="parlons-en" to="/contact">
          Parlons-en !
        </Link>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/wecareatwork/"
        >
          <img
            src={linkedinLogo}
            id="linkedin-navbar-logo"
            alt="linkedin-logo"
          />
        </a>
        <Sidenav />
      </div>
    </nav>
  );
}

export default Navbar;
