import React from "react";
import './Separator.scss';

const Separator = ({ color = "-primary" }) => {
  return <div className="separator-container">
      <div className={"separator " + color}></div>
  </div>;
};

export default Separator;
