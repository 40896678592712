import React, { useState, useEffect } from "react";
//import { useForm } from "react-hook-form";

import * as emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

import "./ContactForm.scss";

const ContactForm = ({ wish }) => {
  const [state, setState] = useState({
    message_html: "",
    from_company: "",
    from_firstName: "",
    from_lastName: "",
    from_email: "",
    from_phone: "",
    from_wish: wish
  });

  useEffect(() => {
    setState(prevState => ({ ...prevState, from_wish: wish }));
  }, [wish]);

  const _handleSubmit = event => {
    event.preventDefault();
    emailjs
      .send("gmail", "template_8zdWXnkb", state, "user_nAIdAD2K1iZAaYaex6wGT")
      .then(res => {
        console.log("Email successfully sent!", res);
        window.location.reload();
      })
      .catch(err => console.error("erreur", err));
  };

  const _handleChange = event => {
    let property = event.target.name;
    let value = event.target.value;
    let newState = state;
    newState[property] = value;
    setState(newState);
  };

  return (
    <form className="contact-form" onSubmit={_handleSubmit}>
      <section className="contact-form-details">
        <label className="contact-form-label">
          Nom *
          <input
            className="contact-form-input"
            name="from_firstName"
            onChange={_handleChange}
            required
          />
          <hr style={{ borderTop: "1px solid #fff" }} />
        </label>
        <label className="contact-form-label">
          Prénom *
          <input
            className="contact-form-input"
            name="from_lastName"
            onChange={_handleChange}
            required
          />
          <hr style={{ borderTop: "1px solid #fff" }} />
        </label>
        <label className="contact-form-label">
          Entreprise
          <input
            className="contact-form-input"
            name="from_company"
            onChange={_handleChange}
          />
          <hr style={{ borderTop: "1px solid #fff" }} />
        </label>
        <label className="contact-form-label">
          Email *
          <input
            className="contact-form-input"
            name="from_email"
            onChange={_handleChange}
            required
          />
          <hr style={{ borderTop: "1px solid #fff" }} />
        </label>
        <label className="contact-form-label">
          Téléphone
          <input
            className="contact-form-input"
            name="from_phone"
            onChange={_handleChange}
          />
          <hr style={{ borderTop: "1px solid #fff" }} />
        </label>
      </section>
      <section className="contact-form-description">
        <label className="contact-form-label">
          Dites-nous tout
          <textarea
            className="contact-form-input contact-form-textarea"
            name="html_message"
            onChange={_handleChange}
            style={{ color: "#1e00c8" }}
          />
          {/* <hr style={{ borderTop: "1px solid #1e00c8", marginBottom: "20px"}} /> */}
        </label>
        <label className="contact-form-label">Vérification</label>
        <ReCAPTCHA sitekey="6LfIedUUAAAAAFp5dGBfjFB-Epmpw4_CHoC3Y8ys"></ReCAPTCHA>
        <input className="submit-button" type="submit" value="Envoyer" />
      </section>
    </form>
  );
};

export default ContactForm;
