import React from "react";
import { NavLink } from "react-router-dom";
import "../Sidenav/Sidenav.scss";

const links = [
  {
    name: "Accueil",
    path: "/",
  },
  {
    name: "Les enjeux",
    path: "les-enjeux",
  },
  {
    name: "Notre offre",
    path: "notre-offre",
  },
  {
    name: "Nos solutions en ligne",
    path: "nos-solutions-en-ligne",
  },
  {
    name: "Qui sommes-nous ?",
    path: "qui-sommes-nous",
  },
  {
    name: "Témoignages clients",
    path: "temoignages-clients",
  },
  {
    name: "Allo Alex",
    externalLink: "https://www.alloalex.com/blog/",
  },
  // {
  //   name: "Recrutement",
  //   path: "404"
  // },
  // {
  //   name: "Presse",
  //   path: "404"
  // },
  // {
  //   name: "Partenaires",
  //   path: "404"
  // },
  {
    name: "Contact",
    path: "contact",
  },
];

const Sidenav = () => {
  const OpenNav = () => {
    document.getElementById("mySidenav").style.marginRight = "0";
  };

  const CloseNav = () => {
    document.getElementById("mySidenav").style.marginRight = "-375px";
  };

  return (
    <>
      <div id="mySidenav" className="sidenav">
        <span className="closebtn" onClick={CloseNav}>
          &times;
        </span>
        {links.map((link, index) => {
          if (link.path)
            return (
              <NavLink
                exact
                key={index}
                onClick={() => {
                  CloseNav();
                }}
                className="sidenav-link"
                to={link.path}
                activeClassName="-active"
              >
                {link.name}
                <span className="active-marker"></span>
              </NavLink>
            );
          else if (link.externalLink)
            return (
              <a
                className="sidenav-link"
                href={link.externalLink}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.name}
              </a>
            );
          else return null;
        })}
      </div>
      <button className="burger-menu" onClick={OpenNav}></button>
    </>
  );
};

export default Sidenav;
