import React from "react";

const Pictogramme = ({ Source, Id, Alt, Title, Text }) => {
  return (
    <div className="pictogrammes-card">
      <img src={Source} className="pictogrammes-card-image" id={Id} alt={Alt} />
      <h3>{Title}</h3>
      <p>{Text}</p>
    </div>
  );
};

export default Pictogramme;
