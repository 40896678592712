import React from "react";
import Dash from "../Dash/Dash";
import Title from "../Title/Title";
import Article from "../Article/Article";
import LinkButton from "../LinkButton/LinkButton";
import "./Soutien.scss";

const Soutien = () => {
  return (
    <section className="soutien-section">
      <div className="soutien-content">
        <Article position="right">
          <Title>
            Allo Alex, pour répondre à vos questions
            <Dash color="red" position="right" />
          </Title>
          <div>
            <p>
              Nous avons mis en place solidairement avec le soutien de clients
              et partenaires, un service gratuit pour répondre à toutes les
              questions sur le cancer et les maladies chroniques au travail.
              Alex est là pour vous guider !
            </p>
            <p>
              Au téléphone, du lundi au vendredi de 9h à 17h, vous pouvez
              l'appeler au numéro vert : 0 800 400 310.
            </p>
            <p>
              Sur son blog, vous pourrez retrouver toutes les informations
              légales, des conseils et des témoignages.
            </p>
          </div>
          <LinkButton
            text="Allo Alex"
            externalLink="https://www.alloalex.com/blog/"
            openInNewTab
          />
        </Article>
      </div>
    </section>
  );
};

export default Soutien;
