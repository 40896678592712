import React from "react";
import "./Pictogrammes.scss";
import pictoLight from "../../assets/pictos/picto-light.svg";
import pictoList from "../../assets/pictos/picto-list.svg";
import pictoTeam from "../../assets/pictos/picto-team.svg";
import pictoSpeech from "../../assets/pictos/picto-speech.svg";
import Pictogramme from "../Pictogramme/Pictogramme";

const Pictogrammes = () => {
  return (
    <div className="pictogrammes-container">
      <Pictogramme
        Source={pictoLight}
        Id={"conseil-picto"}
        Alt={"conseil et stratégie"}
        Title={`Conseil & Stratégie`}
        Text={`Nous mettons notre expertise à votre service pour vous aider à construire votre projet`}
      />
      <Pictogramme
        Source={pictoList}
        Id={"plan-action-picto"}
        Alt={"plan d'action"}
        Title={"Plan d'action"}
        Text={`Nous vous aidons à la mise en oeuvre opérationnelle des actions définies`}
      />
      <Pictogramme
        Source={pictoSpeech}
        Id={"formation-picto"}
        Alt={"formation"}
        Title={"Formation"}
        Text={`Nous sommes un organisme de formation certifié DATADOCK et proposons des formations en présentiel et à distance`}
      />
      <Pictogramme
        Source={pictoTeam}
        Id={"accompagnement-picto"}
        Alt={"accompagnement"}
        Title={"Accompagnement"}
        Text={`Nous développons différents outils on-line et off-line pour vous accompagner`}
      />
    </div>
  );
};

export default Pictogrammes;
