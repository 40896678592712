import React from "react";
import "./VideoPlayer.scss";

function VideoPlayer({name, source}) {
  return (
      <iframe
        className="video-player"
        title={name}
        src={source}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
  );
}

export default VideoPlayer;
