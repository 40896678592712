import React from "react";
import { Link } from "react-router-dom";
import "./NotreOffre.scss";

import conferencePicto from "../../assets/icons/conference.svg";
//import barometrePicto from "../../assets/icons/barometer.svg";
import tablePicto from "../../assets/icons/round-table.svg";
import cafePicto from "../../assets/icons/coffee-cup.svg";
import expoPicto from "../../assets/icons/surface1.svg";
import maskPicto from "../../assets/icons/theatre.svg";
import atelierPicto from "../../assets/icons/Page-1.svg";
import coachingPicto from "../../assets/icons/coach.svg";
import formationsPicto from "../../assets/icons/Outline.svg";
import elearningPicto from "../../assets/icons/notes.svg";
import webSeriesPicto from "../../assets/icons/video.svg";
import webinarPicto from "../../assets/icons/video-tutorial.svg";
import datadocLogo from "../../assets/icons/datadocLogo.jpeg";
import NotreOffreCard from "../../components/NotreOffreCard/NotreOffreCard";
import LinkButton from "../../components/LinkButton/LinkButton";
import Banner from "../../components/Banner/Banner";
import Dash from "../../components/Dash/Dash";
import Title from "../../components/Title/Title";

import headerDesktopImage from "../../assets/images/desktop/header-offre.png";
import headerMobileImage from "../../assets/images/mobile/header-offre.png";
import footerDesktopImage from "../../assets/images/desktop/microphones.png";
import footerMobileImage from "../../assets/images/mobile/microphones.png";

const NotreOffre = () => {
  const cards_info = [
    {
      src: conferencePicto,
      alt: "conference_picto",
      text: "Conférences de sensibilisation",
    },
    {
      src: cafePicto,
      alt: "cafe_picto",
      text: "Cafés rencontres",
    },
    {
      src: webinarPicto,
      alt: "webinars_picto",
      text: "Webinars",
    },
    {
      src: webSeriesPicto,
      alt: "webseries_picto",
      text: "Web séries",
    },
    {
      src: expoPicto,
      alt: "expositions_picto",
      text: "Expositions",
    },
    {
      src: maskPicto,
      alt: "theatre_picto",
      text: "Pièces de théâtre",
    },
    {
      src: formationsPicto,
      alt: "formations_picto",
      text: "Formations **",
    },
    {
      src: elearningPicto,
      alt: "elearning_picto",
      text: "E-learning",
    },
    {
      src: atelierPicto,
      alt: "ateliers_picto",
      text: "Ateliers de co-développement",
    },
    {
      src: coachingPicto,
      alt: "coaching_picto",
      text: "Coaching",
    },
    {
      src: tablePicto,
      alt: "table_picto",
      text: "Soutien Psychologique",
    },
    {
      src: coachingPicto,
      alt: "coaching_picto",
      text: "Accompagnement en ligne",
    },
  ];

  return (
    <>
      <Banner desktopImage={headerDesktopImage} mobileImage={headerMobileImage}>
        <p>Ici, vous ne trouverez pas de recettes toutes faites !</p>
      </Banner>
      <main className="main-offres">
        <section className="offres-description">
          <p>
            Parce que chaque entreprise et chaque personne sont uniques. Parce
            que chaque situation est différente.
          </p>
          <p>
            Que vous soyez une PME ou un grand groupe, du secteur industriel ou
            des services, en région parisienne ou à Marseille - Bordeaux -
            Rennes ou au coeur des Vosges.
          </p>
          <p>
            Ce que nous vous apportons, ce sont les clefs pour co-construire
            avec vous vos meilleures solutions, celles qui correspondent à vos
            besoins.
          </p>
          <p style={{ marginBottom: 20 }}>
            Grâce à notre méthodologie inédite, la méthodologie 4M&copy;,
            inspirée des meilleures pratiques d'intelligence collective et
            d'innovation collaborative. Et si nous discutions de vos besoins ?
          </p>
          <LinkButton
            text="Contactez-nous"
            color="secondary"
            linkPath="/contact"
          />
        </section>
        <section className="offres-exemples">
          <Title color="primary">
            <Dash color="primary" />
            Exemples de solutions* mises en oeuvre
          </Title>
          <p className="offres-exemples-notes">
            *prestations éligibles à la DOETH
          </p>
          <ul className="offres-exemples-cards">
            {cards_info.map((card, index) => {
              return (
                <NotreOffreCard
                  src={card.src}
                  alt={card.alt}
                  text={card.text}
                  key={index}
                />
              );
            })}
          </ul>
          <p className="offres-exemples-notes">
            **Organisme de formation cértifié{' '}
            <img className="inline-logo" src={datadocLogo} alt="datadoc" />
            <br />
            Pour obtenir notre catalogue de formations,{" "}
            <Link to="/contact">contactez-nous</Link>.
          </p>
        </section>
      </main>
      <Banner desktopImage={footerDesktopImage} mobileImage={footerMobileImage}>
        <p>Les résultats chez nos clients ?</p>
        <LinkButton text="Ils en parlent mieux que nous" linkPath="/" />
      </Banner>
    </>
  );
};

export default NotreOffre;
