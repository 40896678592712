import React from "react";
import { Link } from "react-router-dom";

import "../Enjeux/Enjeux.scss";
import EnjeuxArticle from "../../components/EnjeuxArticle/EnjeuxArticle";
import EnjeuxInterview from "../../components/EnjeuxInterview/EnjeuxInterview";
import Banner from "../../components/Banner/Banner";
import LinkButton from "../../components/LinkButton/LinkButton";
import Metric from "../../components/Metric/Metric";

import headerDesktopImage from "../../assets/images/desktop/header-enjeux.png";
import headerMobileImage from "../../assets/images/mobile/header-enjeux.png";
import footerDesktopImage from "../../assets/images/desktop/group.png";
import footerMobileImage from "../../assets/images/mobile/group.png";

const Enjeux = () => {
  return (
    <>
      <Banner desktopImage={headerDesktopImage} mobileImage={headerMobileImage}>
        <p>
          Améliorer la qualité de vie de TOUS vos salariés, c’est un enjeu
          sociétal majeur !
        </p>
      </Banner>
      <main className='enjeux-main'>
        <section className='enjeux-section '>
          <Metric
            number='30%'
            descNumber='de salariés'
            title='C’est le nombre d’actifs concernés par la maladie'
            left=''
            reverse=''
          >
            Chaque jour, 1 000 personnes apprennent qu’elles ont un cancer, 400
            d’entre elles travaillent. 15% des actifs vivent avec une maladie
            chronique et 15% sont des aidants familiaux. Cette réalité rime
            souvent avec difficultés et coûts. 29% des personnes malades voient
            leurs revenus baisser. 1 sur 3 quitte l’emploi 2 ans après le
            diagnostic d’un cancer… <br /> <br /> C’est humainement
            inacceptable.
          </Metric>
        </section>
        <section className='enjeux-section'>
          <Metric
            number='108'
            descNumber="Milliards d'€"
            title='C’est le coût de l’absentéisme par an'
            left='-left'
            reverse='metric-reverse'
          >
            Entre les salaires versés, le temps passé par les autres salariés à
            compenser les dysfonctionnements induits et l’achat de services
            externes non prévus : le coût caché de l’absentéisme est gigantesque
            et équivaut annuellement à 4 059 euros par salarié. <br />
            <br /> C’est économiquement irresponsable.
          </Metric>
        </section>
        <section className='enjeux-section -talk'>
          <p>
            Et cela n’a pas de sens ! Concilier cancers, maladies et travail est
            bénéfique pour TOUS, humainement et économiquement. Ensemble, nous
            pouvons faire de cet enjeu sociétal une source de création de valeur
            pour les salariés, pour les entreprises et notre société. Découvrez{" "}
            <Link to='/notre-offre'>notre offre</Link> et{" "}
            <Link to='/nos-solutions-en-ligne'>nos solutions</Link>.
          </p>
          <LinkButton text='Parlons-en' color='secondary' linkPath='/contact' />
        </section>
        <section className='enjeux-section -articles'>
          <p className='article-title'>Ce qu’en disent les experts</p>
          <EnjeuxArticle />
          <EnjeuxInterview />
        </section>
      </main>
      <Banner desktopImage={footerDesktopImage} mobileImage={footerMobileImage}>
        <p>Envie d’agir pour mieux concilier maladie et travail ?</p>
        <LinkButton text='Parlons-en' linkPath='/contact' />
      </Banner>
    </>
  );
};

export default Enjeux;
