import React from "react";
import "./EnjeuxArticle.scss";
import Laura from "../../assets/images/Laura-Lange.png";

const EnjeuxArticle = props => {
  return (
    <article className="enjeux-article">
      <aside className="enjeux-articles-aside">
        <img src={Laura} alt="Laura Lange" />
      </aside>
      <section className="enjeux-articles-section">
        <p className="enjeux-article-title">Le mot de Laura Lange</p>
        <p className="enjeux-article-job">Philosophe</p>
        <p className="enjeux-article-text">
          “Comment dépasser la traditionnelle et chirurgicale posture du “c’est
          pas nos affaires” ? Que pouvons-nous faire ? Réinvestir autrement les
          valeurs du monde de l’entreprise !
        </p>
        <p className="enjeux-article-text">
          Travailler à montrer combien la maladie n’est pas contradictoire avec
          la notion de performance ni avec celle d’investissement, combien la
          dimension humaine du travail est renforcée à l’épreuve du négatif,
          combien la maladie peut fédérer et créer de vrais liens de solidarité.
        </p>
        <p className="enjeux-article-text">
          Aussi plutôt que de penser à résilier le contrat, pensons la
          résilience, car être malade ce n’est pas être “moins bien” c’est être
          “autre” et autrement sujet à voir et à prendre les choses autrement, à
          ouvrir de nouvelles perspectives. Un plus, non, à l’ère de
          l’innovation et de la prospective ?”
        </p>

        <p>
          Extrait de notre guide pratique <a className="enjeux-article-link" target="_blank" rel="noopener noreferrer" href="https://www.eyrolles.com/Entreprise/Livre/cancer-et-travail-9782212565614/">Cancer et travail</a>
        </p>
      </section>
    </article>
  );
};

export default EnjeuxArticle;
