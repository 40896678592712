import React from 'react'
import './LogoCard.scss'

const LogoCard = ({ name = "logo", source }) => {
    return (
        <div className="logo-card">
            <img src={source} alt={name} className="logo-card-image"/>
        </div>
    )
}

export default LogoCard
