import React, {useState} from "react";

import "./ContactYouAre.scss";

const Choices = [
  {
    text: "Dirigeant(e)",
    type: "type1"
  },
  {
    text: "Responsable RH, RSE ou QVT",
    type: "type1"
  },
  {
    text: "Salarié(e)",
    type: "type2"
  },
  {
    text: "Demandeur d’emploi",
    type: "type2"
  },
  // {
  //   text: "Autre",
  //   type: "type1"
  // }
];

const ContactYouAre = ({ setUserType }) => {
  const [isActive, setIsActive] = useState('');

  return (
    <div className="choice-container">
      {Choices.map((answer, idx) => {
        return (
          <div
            key={idx}
            className={isActive === answer.text ? "choice-card -active" : "choice-card"}
            onClick={() => {
              setUserType(answer.type);
              setIsActive(answer.text)
            }}
          >
            {answer.text}
          </div>
        );
      })}
    </div>
  );
};

export default ContactYouAre;
