import React from "react";
//import playBtn from "../../assets/icons/play-button.svg";
import Dash from "../Dash/Dash"
import "./ElearningCard.scss";

function ElearningCard() {
  return (
    <section className="elearncard">
      <div className="elearncard-content">
        <p className="elearncard-content-text">
          <Dash color="red"/>
          Le premier assistant virtuel pour concilier maladie et travail
          <Dash color="red" position="right"/>
        </p>
      {/* <span className="elearncard-button">
        <img
          src={playBtn}
          id="play-btn"
          alt="play-button"
          height="25px"
          width="25px"
        />
      </span> */}
      </div>
    </section>
  );
}

export default ElearningCard;
