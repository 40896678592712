import React from "react";
import Slider from "react-slick";
import SliderCard from "../SliderCard/SliderCard";
import "./SimpleSlider.scss";
import mpSaisse from "../../assets/images/marie-pierre-saisse.jpg";
import pAndrieux from "../../assets/images/pascal-andrieux-cut.jpeg";

class SimpleSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };

    const cards_info = [
      {
        img: mpSaisse,
        nom: "Marie-Pierre SAISSE",
        titre: "Reponsable RH et référente handicap THALES",
        texte:
          "Aborder le sujet dans l'entreprise avec bienveillance et professionnalisme, contribuer à lever les tabous et prévenir la précarité, une intervention parfaitement en phase avec nos valeurs d'inclusion."
      },
      {
        img: pAndrieux,
        nom: "Pascal ANDRIEUX",
        titre:
          "Directeur des engagements sociaux, sociétaux et RSE de Malakoff Humanis",
        texte:
          "Sensibiliser et accompagner les entreprises pour faciliter le retour à l’emploi de salariés atteints d’un cancer est une priorité, l’expertise de WeCare@Work nous a permis de mieux appréhender la problématique et de mettre en place les leviers d’action."
      }
    ];

    return (
      <Slider {...settings}>
        {cards_info.map((card, idx) => {
          return (
            <div key={idx}>
              <SliderCard
                img={card.img}
                nom={card.nom}
                titre={card.titre}
                texte={card.texte}
              />
            </div>
          );
        })}
      </Slider>
    );
  }
}

export default SimpleSlider;
