import React from "react";
import PropTypes from "prop-types";
import "./Banner.scss";
import { useMediaQuery } from "../../services/useMediaQuery";

const Banner = ({desktopImage, mobileImage, children}) => {
  const mediaIsDesktop = useMediaQuery("(min-width: 769px)");
  const style = {
    background:
      "url('" +
      (mediaIsDesktop ? desktopImage : mobileImage) +
      "') center center / cover no-repeat"
  };
  return (
    <div className="banner" style={style}>
      {children}
    </div>
  );
};

export default Banner;

Banner.propTypes = {
  desktopImage: PropTypes.string,
  mobileImage: PropTypes.string,
}