import React from "react";
import { Link } from "react-router-dom";
import linkedinLogo from "../../assets/icons/linkedin-logo.svg";
import facebookLogo from "../../assets/icons/facebook-logo.svg";
import twitterLogo from "../../assets/icons/twitter-logo.svg";
import instagramLogo from "../../assets/icons/instagram-logo.svg";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-links">
        <Link to="/">Accueil</Link>
        <Link to="/qui-sommes-nous">Qui sommes-nous ?</Link>
        <Link to="/les-enjeux">Les enjeux</Link>
        <Link to="/notre-offre">Notre offre</Link>
        <Link to="/nos-solutions-en-ligne">Nos solutions en ligne</Link>
        <Link to="/temoignages-clients">Témoignages clients</Link>
        <a
          href="https://www.alloalex.com/blog/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Allo Alex
        </a>
        <Link to="/contact">Contact</Link>
        <Link to="/mentions-legales">Mentions légales</Link>

      </div>
      <div className="footer-social">
        <div className="footer-social-title">
          <p>Retrouvez-nous sur les réseaux sociaux</p>
        </div>
        <div className="footer-social-logos">
          <div className="footer-social-container">
            <div className="footer-social-item">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/wecareatwork/"
              >
                <img
                  src={linkedinLogo}
                  className="footer-social-logo"
                  id="linkedin-footer-logo"
                  alt="Linkedin"
                />
              </a>
              <label
                className="footer-social-label"
                htmlFor="linkedin-footer-logo"
              >
                @wcaw
              </label>
            </div>
            <div className="footer-social-item">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/astuszynski?lang=fr"
              >
                <img
                  src={twitterLogo}
                  className="footer-social-logo"
                  id="twitter-logo"
                  alt="Twitter"
                />
              </a>
              <label className="footer-social-label" htmlFor="twitter-logo">
                @wcaw
              </label>
            </div>
          </div>
          <p className="footer-social-container-title">Allo Alex</p>
          <div className="footer-social-container">
            <div className="footer-social-item">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/appellealex/"
              >
                <img
                  src={facebookLogo}
                  className="footer-social-logo"
                  id="facebook-logo"
                  alt="Facebook"
                />
              </a>
              <label className="footer-social-label" htmlFor="facebook-logo">
                @allo_alex
              </label>
            </div>

            <div className="footer-social-item">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/appellealex/"
              >
                <img
                  src={instagramLogo}
                  className="footer-social-logo"
                  id="instagram-logo"
                  alt="Instagram"
                />
              </a>
              <label className="footer-social-label" htmlFor="instagram-logo">
                @allo_alex
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
