import React from "react";

import "./NosSolutionsCard.scss";

const NosSolutionsCard = ({ logo, title, text, children }) => {
  return (
    <div className="nsc">
      <img className="nsc-img" src={logo} alt={title} />
      <h2 className="nsc-title">{title}</h2>
      <p className="nsc-text">{text}</p>
      {children}
    </div>
  );
};

export default NosSolutionsCard;
