import React from "react";
import "./Dash.scss";

const Dash = ({ color = "primary", position = "left" }) => {
  return (
    <span className={`dash -${color} -${position}`}></span>
  );
};

export default Dash;
