import React, { useState } from "react";
import "./Newsletter.scss";
import * as emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

const Newsletters = () => {
  const [state, setState] = useState({
    from_email: ""
  });

  const _handleSubmit = event => {
    event.preventDefault();
    emailjs
      .send("gmail", "newsletter", state, "user_nAIdAD2K1iZAaYaex6wGT")
      .then(res => {
        console.log("Email successfully sent!", res);
        window.location.reload();
      })
      .catch(err => console.error("erreur", err));
  };

  const _handleChange = event => {
    setState({from_email: event.target.value});
  };

  return (
    <div className="newsletters">
      <h2 className="newsletters-title">Newsletter : inscrivez-vous</h2>
      <h3 className="newsletters-text">
        Recevez une dose d'inspiration pour concilier maladie et travail
      </h3>
      <div className="newsletters-inputs">
        <input
          className="newsletters-input"
          placeholder="adresse@example.com"
          onChange={_handleChange}
          name="from_email"
        />
        <ReCAPTCHA sitekey="6LfIedUUAAAAAFp5dGBfjFB-Epmpw4_CHoC3Y8ys"></ReCAPTCHA>
        <button onClick={_handleSubmit} className="newsletters-button">
          S'inscrire
        </button>
      </div>
    </div>
  );
};

export default Newsletters;
