import React from "react";

import "./ContactRadio.scss"

const ContactRadio = ({ data, setUserAsk }) => {
  
  const handleChange = (e) => {
    setUserAsk(e.target.value)
  }

  return (
    <>
      <label className="contact-radio-label">
        <input className="contact-radio-input" type="radio" id={data} name="question" value={data} onChange={handleChange}/>
        <span className="radio-button-control"></span>
        <span className="radio-button-label">{data}</span>
      </label>
    </>
  );
};

export default ContactRadio;
