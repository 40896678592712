import React from "react";
import "./SliderCard.scss";

const SliderCard = props => {
  const { img, nom, titre, texte } = props;
  return (
    <article className="slider-card">
      <header className="card-header">
        <img alt="card_img" src={img} className="card-photo" />
        <div className="card-header-text">
          <h3 className="slider-card-title">{nom}</h3>
          <p className="slider-card-subtitle">{titre}</p>
        </div>
      </header>
      <p className="card-content">{texte}</p>
    </article>
  );
};

export default SliderCard;
