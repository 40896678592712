import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Home from './pages/Home';
import Enjeux from './pages/Enjeux/Enjeux';
import NosSolutions from './pages/NosSolutions/NosSolutions';
import NotreOffre from './pages/NotreOffre/NotreOffre';
import QuiSommeNous from './pages/QuiSommeNous/QuiSommeNous';
import Contact from './pages/Contact/Contact';
import NavBar from './components/Navbar/Navbar';
import Newsletters from './components/Newsletter/Newsletter';
import Footer from './components/Footer/Footer';
import Temoignages from './pages/Temoignages/Temoignages';

import './App.scss';
import LegalInfo from './pages/LegalInfo/LegalInfo';

function App() {
  return (
    <>
      <script
        type='text/javascript'
        id='hs-script-loader'
        async
        defer
        src='//js.hs-scripts.com/7464979.js'
      ></script>
      <BrowserRouter>
        <ScrollToTop>
          <NavBar />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/notre-offre' component={NotreOffre} />
            <Route path='/les-enjeux' component={Enjeux} />
            <Route path='/nos-solutions-en-ligne' component={NosSolutions} />
            <Route path='/qui-sommes-nous' component={QuiSommeNous} />
            <Route path='/temoignages-clients' component={Temoignages} />
            <Route path='/contact' component={Contact} />
            <Route path='/mentions-legales' component={LegalInfo} />
          </Switch>
          <Newsletters />
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
