import React from "react";
import Banner from "../../components/Banner/Banner";
import TeamCardList from "../../components/TeamCardList/TeamCardList";
import Article from "../../components/Article/Article";
import Dash from "../../components/Dash/Dash";
import Title from "../../components/Title/Title";

import "./QuiSommeNous.scss";

import headerDesktopImage from "../../assets/images/desktop/header-quisommesnous.png";
import headerMobileImage from "../../assets/images/mobile/header-quisommesnous.png";
import footerDesktopImage from "../../assets/images/desktop/footer-quisommesnous.png";
import footerMobileImage from "../../assets/images/mobile/footer-quisommesnous.png";
import LinkButton from "../../components/LinkButton/LinkButton";

const QuiSommeNous = () => {
  return (
    <>
      <Banner desktopImage={headerDesktopImage} mobileImage={headerMobileImage}>
        <p>
          Nous sommes des hommes et des femmes d’entreprises, tous touchés par
          la maladie
        </p>
      </Banner>
      <main className="qsn-container">
        <Article margin>
          <Title color="primary">
            <Dash color="primary" />
            100% alignés
          </Title>
          <p>
            Nos expériences de vie, en tant que malade ou aidant, et nos
            parcours professionnels multiples, ont forgé notre volonté
            d’entreprendre dans la Tech for good. Nous avons la conviction que
            l’expérience de vie de la maladie peut devenir un atout
            professionnel pour les personnes concernées, et qu’elle peut rendre
            l’entreprise plus performante, dans une logique gagnant-gagnant
          </p>
        </Article>
        <Article position="center">
          <Title color="primary">
            <Dash color="primary" />
            Notre manifeste
          </Title>
          <p>
            Nous sommes des femmes et des hommes d’entreprise, tous confrontés à
            la maladie.
            <br />
            Parce que nous sommes toujours plus nombreux.
            <br />
            Parce qu’aujourd’hui la maladie au travail est synonyme de
            difficultés et de coûts, pour les personnes, pour les entreprises,
            pour la société.
            <br />
            Parce que nous revendiquons nos devoirs et nos droits
            constitutionnels que sont le devoir de travailler et le droit à
            l’emploi.
            <br />
            Parce que la maladie nous a obligé à réagir, à nous adapter, à nous
            appuyer sur nos valeurs pour prendre des chemins nouveaux, plus
            humains, elle a développé chez nous des qualités et des compétences
            que nous sommes heureux de mettre à votre service.
            <br />
            Parce que chaque jour nous faisons la preuve que concilier maladie
            et travail est possible et source d’une meilleure qualité de vie au
            travail pour tous, dans notre entreprise et chez nos clients.
            <br />
            Parce que mieux concilier maladie et travail est une source de
            création de valeur humaine et économique.
            <br />
            Parce que nous souhaitons offrir à nos enfants des conditions de
            soins et de travail de qualité.
            <br />
            Nous nous retrouvons et nous engageons dans Wecare@Work.
            <br />
            Pour mieux concilier cancers, maladies et travail et améliorer la
            qualité de vie de TOUS les salariés.
          </p>
        </Article>
        <section className="qsn-section -team">
          <Title color="primary">
            Notre équipe
            <Dash color="primary" position="right" />
          </Title>
          <TeamCardList />
          <LinkButton
            linkPath="/contact"
            text="Parlons-en"
            color="secondary"
          />
        </section>
        <Article margin position="right">
          <Title color="primary">
            100% solidaire
            <Dash color="primary" position="right" />
          </Title>
          <p>
            Nous avons mis en place, solidairement et avec le soutien de
            partenaires, une hot line gratuite pour répondre à toutes questions
            sur le cancer et les maladies chroniques au travail.
          </p>
          <LinkButton
            text="Allo Alex"
            externalLink="https://www.alloalex.com/blog/"
            color="secondary"
            openInNewTab
          />
        </Article>
      </main>
      <Banner desktopImage={footerDesktopImage} mobileImage={footerMobileImage}>
        <p>Vous avez envie d’agir à nos côtés ? Rejoignez notre équipe ou notre réseau national d’experts !</p>
      </Banner>
    </>
  );
};

export default QuiSommeNous;
