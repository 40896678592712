import React from "react";

import TeamCard from "../TeamCard/TeamCard";

import "./TeamCardList.scss";

import AnneSophie from "../../assets/images/desktop/Anne-SophieT.png";
import Carmelita from "../../assets/images/desktop/Carmelita.png";
import ClaireD from "../../assets/images/desktop/ClaireD.png";
import LaurenceC from "../../assets/images/desktop/LaurenceC.png";
import Lea from "../../assets/images/desktop/Lea.png";
import Julie from "../../assets/images/desktop/JulieM.png";
import Nathalie from "../../assets/images/desktop/nathalie.png";
import VeroniqueD from "../../assets/images/desktop/VeroniqueD.png";
import MainW from "../../assets/images/desktop/main-w.png";
import MainO from "../../assets/images/desktop/main-o.png";
import MainR from "../../assets/images/desktop/main-r.png";
import MainK from "../../assets/images/desktop/main-k.png";

import AnneSophieMob from "../../assets/images/mobile/Anne-SophieT.png";
import CarmelitaMob from "../../assets/images/mobile/Carmelita.png";
import ClaireDMob from "../../assets/images/mobile/ClaireD.png";
import LaurenceCMob from "../../assets/images/mobile/LaurenceC.png";
import LeaMob from "../../assets/images/mobile/Lea.png";
import JulieMob from "../../assets/images/mobile/JulieM.png";
import NathalieMob from "../../assets/images/mobile/nathalie.png";
import VeroniqueDMob from "../../assets/images/mobile/VeroniqueD.png";
import MainWMob from "../../assets/images/mobile/main-w.png";
import MainOMob from "../../assets/images/mobile/main-o.png";
import MainRMob from "../../assets/images/mobile/main-r.png";
import MainKMob from "../../assets/images/mobile/main-k.png";

const cards = [
  {
    image: AnneSophie,
    imageMob: AnneSophieMob,
    name: "Anne-Sophie TUSZYNSKI",
    job: "Fondatrice et CEO",
    location: "Paris",
    linkedinLink: "https://www.linkedin.com/in/anne-sophie-tuszynski-67280510/",
    hoveredTitle: "WE",
    hoveredText: "Ensemble, pour faire changer la place des malades dans le monde du travail et créer de la valeur pour tous, humaine et économique."
  },
  {
    image: ClaireD,
    imageMob: ClaireDMob,
    name: "Claire DÉSARNAUD",
    job: "Consultante associée",
    location: "Marseille",
    linkedinLink: "https://www.linkedin.com/in/claire-d%C3%A9sarnaud-5171102/",
    hoveredTitle: "ENGAGÉS",
    hoveredText: "Suite à nos expériences de vie de la maladie en tant que patients ou aidants, nous avons décidé de nous engager au quotidien."
  },
  {
    image: LaurenceC,
    imageMob: LaurenceCMob,
    name: "Laurence CASSOT",
    job: "Consultante",
    location: "Bordeaux",
    linkedinLink: "https://www.linkedin.com/in/laurence-cassot-7a190266/",
    hoveredTitle: "COLLECTIF",
    hoveredText: "Nos forces individuelles forment un groupe d’experts complet qui nous permet de nous adapter avec agilité à tous les besoins de nos clients."
  },
  {
    image: VeroniqueD,
    imageMob: VeroniqueDMob,
    name: "Veronique DELESTRE",
    job: "Consultante",
    location: "Rennes",
    linkedinLink: "https://www.linkedin.com/in/v%C3%A9ronique-delestre-06865924/",
    hoveredTitle: "ALIGNÉS",
    hoveredText: "Ce que nous prônons chez nos clients nous nous l’appliquons avant tout en interne et avec nos partenaires."
  },
  {
    image: Julie,
    imageMob: JulieMob,
    name: "Julie MORIN",
    job: "Consultante Aix-en-Provence",
    location: "",
    linkedinLink: "https://www.linkedin.com/in/julie-morin-29825551/",
    hoveredTitle: "",
    hoveredText: ""
  },
  {
    image: Lea,
    imageMob: LeaMob,
    name: "Léa ROLET",
    job: "Chargée de développement Allo Alex",
    location: "",
    linkedinLink: "https://www.linkedin.com/in/learolet/",
    hoveredTitle: "ENTHOUSIASTES",
    hoveredText: "Nous savons tous les matins pourquoi nous nous levons, nous aimons notre travail et savons aussi apprécier toutes les bonnes choses de la vie."
  },
  {
    image: Nathalie,
    imageMob: NathalieMob,
    name: "Nathalie POYET",
    job: "Chargée de développement Allo Alex",
    location: "",
    linkedinLink: "https://www.linkedin.com/in/nathalie-poyet-0473bb69/",
    hoveredTitle: "RESILIENTS",
    hoveredText: "Nous voyons en chaque difficulté une occasion de grandir et nous reconnaissons la maladie comme une compétence à part entière."
  },
  {
    image: Carmelita,
    imageMob: CarmelitaMob,
    name: "Carmelita NUNES",
    job: "Office manager",
    location: "",
    linkedinLink: "https://fr.linkedin.com/in/carmelita-nunes-163aaa59",
    hoveredTitle: "AT-TENTIFS",
    hoveredText: "Nous portons attention aux besoins et aux attentes de chacun, quelle que soit le type d’entreprise et la partie prenante concernée."
  },
  {
    image: MainW,
    imageMob: MainWMob,
    name: "WANTED",
    job: "Peut-être vous ?",
    location: "",
    linkedinLink: "",
    hoveredTitle: "WANTED",
    hoveredText: "Nous recherchons plusieurs profils (consultant ou développeur), alors si vous souhaitez en."
  },
  {
    image: MainO,
    imageMob: MainOMob,
    name: "WANTED",
    job: "Ou vous ?",
    location: "",
    linkedinLink: "",
    hoveredTitle: "OUVERT",
    hoveredText: "Vous aimez les challenges et vous souhaitez participer activement à un projet qui a du sens ? Contactez-nous !"
  },
  {
    image: MainR,
    imageMob: MainRMob,
    name: "WANTED",
    job: "Ou encore vous ?",
    location: "",
    linkedinLink: "",
    hoveredTitle: "RAISON d’ETRE",
    hoveredText: "Notre raison d’etre fait particulièrement écho en vous et vous aimeriez rejoindre notre équipe de care givers ?"
  },
  {
    image: MainK,
    imageMob: MainKMob,
    name: "WANTED",
    job: "Et vous ?",
    location: "",
    linkedinLink: "",
    hoveredTitle: "KEEP FIGTHING",
    hoveredText: "Keep figthing est votre leitmotiv autant que nous alors parlons-en !"
  }
];

const TeamCardList = () => {
  return (
    <div className="card-list">
      {cards.map((card, idx) => {
        return <TeamCard card={card} key={idx} />;
      })}
    </div>
  );
};

export default TeamCardList;
