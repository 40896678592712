import React from "react";
import "./NotreOffreCard.scss";

const NotreOffreCard = props => {
  return (
    <li className="offre-card">
      <img className="offre-card-img" src={props.src} alt={props.alt} />
      <p className="offre-card-text">{props.text}</p>
    </li>
  );
};

export default NotreOffreCard;
