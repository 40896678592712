import React from "react";
import "./Article.scss";

const Article = ({ position = "left", margin, children }) => {
  return (
    <article className={`article -${position} ${margin ? `-margin-${position}` : ""}`}>
      {children}
    </article>
  );
};

export default Article;
