import React from "react";
import "./Header.scss";
import LinkButton from "../LinkButton/LinkButton";
import Dash from "../Dash/Dash"

function Header() {
  return (
    <header className="header-container">
      <section className="header-content">
        <div className="header-mid">
          <p className="header-content-title">
            <Dash color="red"/>
            Améliorer la qualité de vie de <strong>TOUS</strong> vos salariés y
            compris dans leurs moments de fragilité !
          </p>
          <LinkButton text="Voir les enjeux" linkPath="/les-enjeux"/>
        </div>
      </section>
    </header>
  );
}

export default Header;
