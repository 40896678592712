import React from "react";
import "./LegalInfo.scss";
import Banner from "../../components/Banner/Banner";
import headerDesktopImage from "../../assets/images/desktop/footer-quisommesnous.png";
import headerMobileImage from "../../assets/images/mobile/footer-quisommesnous.png";
import Dash from "../../components/Dash/Dash";
import Title from "../../components/Title/Title";
import Article from "../../components/Article/Article";
const LegalInfo = () => {
  return (
    <>
      <Banner desktopImage={headerDesktopImage} mobileImage={headerMobileImage}>
        <p>
          Vous avez envie d'agir à nos côtés ?<br />
          Rejoignez-nous !
        </p>
      </Banner>
      <main className="legal-info-container">
        <Article>
          <Title color="primary">
            <Dash color="primary" />
            Mentions légales
          </Title>
          <p>
            <b>Editeur</b>
            <br />
            Le site wecareatwork.com est édité par Kepler HR. SAS au capital de
            18.660 euros - RCS NANTERRE 810 156 828, domiciliée au 67 rue des
            Rabats – 92160 Antony (France). Organisme de formation
            n°11922281992, certifié DATADOCK. Kepler HR est totalement
            indépendante et reste totalement maître du contenu éditoriale du
            site. Pour contacter l’éditeur : bonjour@wecareatwork.com
          </p>
          <p>
            <b>Propriété littéraire et artistique</b>
            <br />
            L’ensemble des informations du site wecareatwork.com, incluant les
            textes, les éléments graphiques, les éléments sonores et
            audiovisuels, les outils de suivi, est la propriété de Kepler HR,
            éditeur du site, et est protégé par les lois internationales sur les
            droits d’auteur. Aucun matériel de ce site ne peut être reproduit,
            modifié, distribué, transmis, republié, affiché ou diffusé sans
            l’autorisation écrite préalable de Kepler HR. Pour toute demande
            relative à l’utilisation du contenu, veuillez écrire à :
            bonjour@wecareatwork.com
          </p>
          <p>
            <b>Données à caractère personnel</b>
            <br />
            Conformément à la loi n°78-17 du 6 janvier 1978 modifiée, le
            traitement des données personnelles fait l’objet d’une déclaration
            normale auprès de la Commission Nationale de l’Informatique et des
            Libertés (Cnil), qui en a délivré récépissé sous le numéro 202 7614
            V 0, à la date du 24 Janvier 2017. Vos données à caractère personnel
            sont exclusivement destinées au responsable de traitement et ne
            seront en aucun cas communiquées à des tiers non autorisés. Vous
            bénéficiez d’un droit d’accès, de rectification et d’opposition des
            données à caractère personnel vous concernant que vous pouvez
            exercer en adressant un mail à bonjour@wecareatwork.com ou un
            courrier par voie postale à Kepler HR, 67 rue des Rabats, 92160
            Antony (France)
          </p>
        </Article>
      </main>
    </>
  );
};

export default LegalInfo;
