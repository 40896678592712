import React from "react";
import PropTypes from "prop-types";
import "./LinkButton.scss";
import { Link } from "react-router-dom";
// kick le - pr color
const LinkButton = ({
  linkPath,
  externalLink,
  text,
  color = "-white",
  disabled,
  openInNewTab
}) => {
  if (linkPath)
    return (
      <Link
        className={`link-button -${color} ${
          disabled ? "-disabled-link" : ""
        }`}
        to={linkPath}
        target={openInNewTab && '_blank'}
        rel={openInNewTab && 'noopener noreferrer'}
      >
        {text}
      </Link>
    );
  else if (externalLink)
    return (
      <a
        href={externalLink}
        className={`link-button -${color} ${
          disabled ? "-disabled-link" : ""
        }`}
        target={openInNewTab && '_blank'}
        rel={openInNewTab && 'noopener noreferrer'}
      >
        {text}
      </a>
    );
  else console.debug("LinkButton error: Cannot find link to target")
};

export default LinkButton;

LinkButton.propTypes = {
  linkPath: PropTypes.string,
  externalLink: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.oneOf(["white", "primary", "secondary"]),
  disabled: PropTypes.bool,
  openInNewTab: PropTypes.bool,
};
