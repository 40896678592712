import React from "react";
import "./Temoignages.scss";

import Banner from "../../components/Banner/Banner";
import Title from "../../components/Title/Title";
import SimpleSlider from "../../components/SimpleSlider/SimpleSlider";
import LinkButton from "../../components/LinkButton/LinkButton";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import LogoCard from "../../components/LogoCard/LogoCard";
import Dash from "../../components/Dash/Dash";

import HeaderBannerDesktop from "../../assets/images/desktop/microphones.png";
import HeaderBannerMobile from "../../assets/images/mobile/microphones.png";

import FooterBannerDesktop from "../../assets/images/desktop/group.png";
import FooterBannerMobile from "../../assets/images/mobile/group.png";

import Accenture from "../../assets/images/partner/accenture.png";
import Airbus from "../../assets/images/partner/airbus.png";
import Altran from "../../assets/images/partner/altran.png";
import Artelia from "../../assets/images/partner/artelia.png";
import Axa from "../../assets/images/partner/axa.png";
import Bqp from "../../assets/images/partner/banque-populaire.png";
import Bristol from "../../assets/images/partner/bristol.png";
import Ca from "../../assets/images/partner/ca-aquitaine.png";
import CreditFoncier from "../../assets/images/partner/credit-foncier.png";
import Euler from "../../assets/images/partner/euler-hermes.png";
import Foncia from "../../assets/images/partner/foncia.png";
import Gilead from "../../assets/images/partner/gilead.png";
import Itce from "../../assets/images/partner/itce.png";
import MalakoffMederic from "../../assets/images/partner/malakoff-mederic.png";
import MalakoffHumanis from "../../assets/images/partner/malakoffhumanis.jpg";
import Roche from "../../assets/images/partner/roche.png";
import Sncf from "../../assets/images/partner/sncf.png";
import Thales from "../../assets/images/partner/thales.jpg";
import Zalis from "../../assets/images/partner/zalis.png";

const logos = [
  {
    name: "Accenture",
    image: Accenture
  },
  {
    name: "Airbus",
    image: Airbus
  },
  {
    name: "Altran",
    image: Altran
  },
  {
    name: "Artelia",
    image: Artelia
  },
  {
    name: "Axa",
    image: Axa
  },
  {
    name: "Bqp",
    image: Bqp
  },
  {
    name: "Bristol",
    image: Bristol
  },
  {
    name: "Ca",
    image: Ca
  },
  {
    name: "CreditFoncier",
    image: CreditFoncier
  },
  {
    name: "Euler",
    image: Euler
  },
  {
    name: "Foncia",
    image: Foncia
  },
  {
    name: "Gilead",
    image: Gilead
  },
  {
    name: "Itce",
    image: Itce
  },
  {
    name: "MalakoffMederic",
    image: MalakoffMederic
  },
  {
    name: "MalakoffHumanis",
    image: MalakoffHumanis
  },
  {
    name: "Roche",
    image: Roche
  },
  {
    name: "Sncf",
    image: Sncf
  },
  {
    name: "Thales",
    image: Thales
  },
  {
    name: "Zalis",
    image: Zalis
  }
];

const Temoignages = () => {
  return (
    <>
      <Banner
        desktopImage={HeaderBannerDesktop}
        mobileImage={HeaderBannerMobile}
      >
        <p>Les résultats chez nos clients ?</p>
      </Banner>
      <section className="page-section -flex">
        <p>
          Une parole libérée, un dialogue ouvert, un engagement renforcé, une
          meilleure qualité de vie de TOUS les salariés, un taux d’absentéisme
          en baisse, une performance globale améliorée et durable… Mais ils en
          parlent mieux que nous !
        </p>
      </section>
      <section className="page-section -center">
        <Title color="light-blue">
          <Dash color="light-blue" />
          La preuve en image
        </Title>
        <VideoPlayer
          name="temoignage-video"
          source="https://www.youtube.com/embed/SWVN3Rw5BdU"
        />
      </section>
      <section className="page-section -right flex-column-section">
        <Title color="light-blue">
          En quelques mots
          <Dash color="light-blue" position="right" />
        </Title>
        <SimpleSlider />
      </section>
      <section className="page-section">
        <Title color="light-blue">
          <Dash color="light-blue" />
          Ils nous font confiance
        </Title>

        {logos.map(logo => (
          <LogoCard source={logo.image} />
        ))}
      </section>
      <Banner
        desktopImage={FooterBannerDesktop}
        mobileImage={FooterBannerMobile}
      >
        <p>Envie d’agir pour mieux concilier maladie et travail ? </p>
        <LinkButton text="Parlons-en" linkPath="/contact" />
      </Banner>
    </>
  );
};

export default Temoignages;
