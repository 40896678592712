import React from "react";

import Title from "../Title/Title";

import "./Metric.scss";

const Metric = props => {
  return (
    <div className={`metric ${props.reverse}`} >
      <div className="circle-metric">
        <p>
          <span>{props.number}</span>
          <br />
          {props.descNumber}
        </p>
      </div>
      <div className={`metric-text-content ${props.left}`}>
        <Title color="pink">{props.title}</Title>
        <p>{props.children}</p>
      </div>
    </div>
  );
};

export default Metric;
