import React from "react";

import "./Title.scss"

const Title = ({color = "white", size = "", children}) => {
    return (
    <h2 className={`title -${color} -${size}`}>
      {children}
    </h2>
  );
};

export default Title;
