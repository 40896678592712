import React from "react";

import LinkButton from "../LinkButton/LinkButton";
import "./TeamCard.scss";

const TeamCard = ({ card }) => {
  return (
    <div className="team-card">
      <picture className="team-card-picture">
        <source srcSet={card.image} media="(min-width: 769px)" />
        <img className="team-card-img" src={card.imageMob} alt={card.name} />
        <div className="team-card-hover">
          <p className="team-card-hover-title">{card.hoveredTitle}</p>
          <p className="team-card-hover-text">{card.hoveredText}</p>
          { card.linkedinLink && <LinkButton
            text="LinkedIn"
            externalLink={card.linkedinLink}
            color="secondary"
            openInNewTab
          />}
        </div>
      </picture>
      <p className="team-card-name">{card.name}</p>
      <p className="team-card-info">{card.job}</p>
      <p className="team-card-info">{card.location}</p>
    </div>
  );
};

export default TeamCard;
